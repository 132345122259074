.meta-container {
  font-size: 13px;

  .bg-lightgray {
    background-color: #e9ecef;
  }

  .nav-link.active {
    background-color: #e9ecef !important;
    border-bottom: 1px solid transparent !important;
  }
}

.max-h-600px {
  max-height: 600px;
}

.overflow-y-auto {
  overflow-y: auto;
}
