@import '../../theme/variables';

.downshift-container {
  position: relative;

  ul {
    position: absolute;
    list-style-type: none;
    padding: 0;
    z-index: 99999;

    &:empty {
      display: none;
    }

    li {
      padding: 5px 10px;
    }
  }
}

.modal-cadastro-produto {
  label {
    color: $gray-600;
    font-size: 0.8em;
  }

  .observacao-campo {
    font-size: 11px;
    color: $gray-500;
    font-weight: 400;
  }
}
