.custom-accordion-container {
  $accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='black'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
  $accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='black'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;

  .custom-accordion-body {
    padding: 0;

    > div:first-child {
      border-top: none !important;
    }
  }

  .custom-accordion-button {
    padding: 4px;
    border-radius: 0;
    font-style: italic;
    font-size: 12px;
    background-color: #e9ecef;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    color: #212529;
    text-align: left;
    border: 0;
    overflow-anchor: none;
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out,
      border-radius 0.15s ease;

    &::after {
      flex-shrink: 0;
      width: 1.25rem;
      height: 1.25rem;
      margin-left: auto;
      content: '';
      background-repeat: no-repeat;
      background-size: 1.25rem;
      transition: transform 0.2s ease-in-out;
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='black'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
    }
  }

  .custom-accordion-button-show {
    font-style: normal;
  }

  .custom-accordion-button-hide::after {
    transform: rotate(-180deg);
  }
}
