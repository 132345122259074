.table-faturamento-comissao {
  .btn-xs {
    font-size: 11px;
    padding: 2px 5px;
  }

  .form-control.input-comissao-recebida {
    font-size: 13px;
    padding: 5px;
  }

  .w-250px {
    width: 250px;
  }
}
