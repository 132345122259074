.relatorio {
  .td-dias {
    text-align: center;
  }

  .table {
    font-size: 0.8em;
  }

  .table-meses {
    text-align: center;
    vertical-align: middle;
  }

  th {
    text-align: center;
  }
}
