.content-scroll {
  input[type='text'] {
    width: 100%;
    background: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #ffffff;
    height: 60px;
    color: #ffffff;
  }
  input:focus {
    outline: none;
  }

  .list-chamados {
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 1px solid #707070;
    background-color: #ededed;
  }

  .box-chamado {
    //   border-left: 5px solid green;
    border-bottom: 5px solid #707070;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 1%;
    padding-right: 1%;
  }

  .data {
    font-size: 12px;
    color: #a8a8a8;
  }

  .titulo {
    font-size: 18px;
    color: #707070;
  }

  .descricao {
    font-size: 18px;
    color: #a8a8a8;
  }

  .text-dentro {
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .caixa-pesquisa {
    height: 100px;
    background-color: #707070;
    margin-bottom: 5%;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 2px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #707070;
  }
}

.content-chamado {
  .text-top {
    font-size: 18px;
  }

  .btn-novo {
    color: #006591;
  }

  .btn-primary {
    background-color: #006591 !important;
    border-color: #006591 !important;
  }

  .caixa-titulo {
    height: 50px;
    background-color: #707070;
    margin-bottom: 5%;
  }

  .text-titulo {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
  }

  .messages {
    overflow-y: auto;
    height: 400px;
    padding: 5px;
  }

  .message-owner {
    float: right;
    width: 80%;
    padding: 2%;
    font-size: 12px;
    border-radius: 5px;
    background-color: #006591;
    color: #ffffff;
    margin-top: 1%;
    margin-bottom: 4%;
  }

  .message-response {
    float: left;
    width: 80%;
    padding: 2%;
    font-size: 12px;
    border-radius: 5px;
    background-color: #140042;
    color: #ffffff;
    margin-top: 2%;
    margin-top: 1%;
    margin-bottom: 4%;
  }

  .bottom-input {
    height: 25%;
    bottom: 20;
    right: 0;
    padding-right: 2%;

    // position: fixed;
    // z-index: 1000;
    background-color: #ffffff;
    width: 100%;
  }

  .data-message {
    text-align: right;
    padding-top: 2%;
    width: 100%;
    color: #ffffff;
    background-color: transparent;
  }

  .btn-finalizar {
    width: 250px;
    height: 50px;
    background-color: #a60000;
    color: #ffffff;
    font-size: 12px;
    border-radius: 5px;
    margin-bottom: 10%;
    border: none;
    margin-right: 2%;
  }

  .btn-enviar {
    width: 250px;
    height: 50px;
    background-color: #006591;
    color: #ffffff;
    font-size: 12px;
    border-radius: 5px;
    margin-bottom: 10%;
    border: none;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 2px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #707070;
  }
}
