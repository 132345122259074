.card {
  .border-left-primary {
    border-left: 0.25rem solid #4e73df !important;
  }
  .text-primary {
    color: #4e73df !important;
  }
  .border-left-success {
    border-left: 0.25rem solid #1cc88a !important;
  }
  .text-success {
    color: #1cc88a !important;
  }
  .border-left-meta {
    border-left: 0.25rem solid #a156c4 !important;
  }
  .text-meta {
    color: #a156c4 !important;
  }
  .border-left-info {
    border-left: 0.25rem solid #36b9cc !important;
  }
  .text-info {
    color: #36b9cc !important;
  }
  .border-left-warning {
    border-left: 0.25rem solid #f6c23e !important;
  }
  .text-warning {
    color: #f6c23e !important;
  }
}
