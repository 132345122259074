@import '../../theme/variables';

.carteira {
  max-height: 400px;
  overflow: auto;

  .list-item {
    font-size: 13px;
    .cnpj {
      font-size: 0.8em;
      color: $gray-500;
    }
    .fantasia {
      color: $gray-500;
      font-size: 1em;
    }
  }
}
