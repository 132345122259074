@import '../../../theme/variables';

.input-image-preview-container {
  border: 1px solid #ccc;
  height: 150px;

  input[type='file'] {
    display: none;
  }

  label {
    cursor: pointer;
    font-size: 40px;
    color: $gray-600;
    width: 100%;

    div {
      padding: 0 22px;
      font-size: 16px;
    }
  }

  img {
    padding: 10px;
    height: 100px;
    max-width: 100%;
  }
  svg {
    padding: 10px;
    height: 40px;
  }

  .file-preview {
    margin: 0 10px;
  }
}
