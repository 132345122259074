@import '../../theme/variables';

.pedido-page {
  .sem-produtos {
    text-align: center;
    color: $red;
    font-size: 1.2em;
    padding: 5px;
  }

  .codigo-pedido-loja {
    margin-top: 5px;
  }

  .representada-adicionada {
    margin-top: 10px;
  }
  .texto-observacao {
    color: $gray-600;
    font-size: 0.8em;
    font-weight: 300;
  }
  label {
    color: $gray-600;
  }

  .produto-filtro {
    label {
      font-size: 0.8em;
    }
  }

  .link-cadastro {
    text-align: right;
    margin-top: 5px;

    a,
    button {
      color: $secondary !important;
      font-size: 0.9em;
      text-decoration: none;
      background: transparent;
      border: none;
    }
  }

  .input-produto-filter {
    .input-group-text,
    .form-control {
      background-color: $white;
      border: none;
      border-bottom: 1px solid #919191;
      border-radius: 0;
    }
  }

  .lista-produto {
    max-height: 300px;
    color: $gray-500;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 10px 0;
    .lista-produto-item {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .nome {
      color: $secondary;
    }
    .codigo {
      font-size: 0.8em;
    }

    @media only screen and (max-width: 700px) {
      .add-produto-mobile {
        display: block;
      }
      .add-produto-desktop {
        display: none;
      }
    }

    @media only screen and (min-width: 701px) {
      .add-produto-mobile {
        display: none;
      }
      .add-produto-desktop {
        display: block;
      }
    }
  }

  .titulo-adicionados {
    color: $white;
    background-color: $gray-600;
    padding: 5px;
    text-align: center;
  }

  .produtos-pedido {
    background-color: $gray-200;
    margin: 10px 0px;
    padding: 10px;
    font-weight: 300;

    label {
      font-weight: normal;
    }

    .imagem-produto img {
      max-width: 150px !important;
      max-height: 150px !important;
    }

    .imagem-produto-sm img {
      max-width: 70px !important;
      max-height: 70px !important;
    }

    .remover-produto {
      color: $danger;
      cursor: pointer;
    }

    .dados-produto {
      padding-bottom: 10px;
    }

    .erro-produto {
      text-align: center;
      margin-top: 5px;
    }
  }

  .valor-total label {
    font-size: 1.3em;
  }

  .label-total-final {
    text-align: left;
    width: 150px;
  }

  .valor-total-final {
    text-align: right;
    width: 150px;
  }

  .btn-avancar {
    margin-top: 10px;
    margin-left: 10px;
  }

  .link-remover {
    color: $red;
    font-size: 11px;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: right;
    cursor: pointer;
  }
  .total-pedido-destaque {
    font-size: 1.5em !important;
    font-weight: bold;
  }
}

.pedido-revisao-page {
  color: $gray-600;
  .dados-loja {
    font-weight: 300;
    label {
      font-weight: normal;
    }
  }

  .mensagem-preco {
    font-size: 1.2em;
    text-align: center;
  }

  .produtos-pedido {
    background-color: $gray-200;
    margin: 10px 0px;
    padding: 10px;
    font-weight: 300;
    font-size: 0.9em;

    label {
      font-weight: normal;
    }

    .imagem-produto img {
      max-width: 100px !important;
      max-height: 100px !important;
    }

    .imagem-produto-sm img {
      max-width: 70px !important;
      max-height: 70px !important;
    }

    .dados-produto {
      padding-bottom: 10px;
    }

    .erro-produto {
      font-size: 0.9em;
      text-align: center;
      margin-top: 5px;
    }
  }

  .total-pedido {
    font-size: 1.2em;
  }

  .check-pedido-padrao {
    margin-top: 10px;
    input {
      margin-right: 10px;
    }
  }

  .btn-finalizar {
    margin-top: 20px;
    .btn {
      border-radius: 0;
      font-size: 1.3em;
      padding: 10px;
      width: 100%;
      height: 100%;
    }
  }
}

.pedido-finalizado-page {
  .icon-check {
    font-size: 150px;
    color: $success;
    text-align: center;
  }

  .dados-pedido {
    font-size: 1.3em;
    font-weight: 300;
    text-align: center;
  }

  .representadas {
    font-size: 0.7em;
  }

  .btn-finalizar {
    margin-top: 20px;
    .btn {
      border-radius: 0;
      font-size: 1.3em;
      padding: 10px;
      width: 100%;
      height: 100%;
    }
  }
  .pedido-analise {
    color: $red;
  }
}
