@import '../../theme/variables';
.pedido-faturamento-page {
  .filter-bar2 {
    margin-top: 0px;
    font-size: small;

    button {
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 5px;
      margin-top: 16px;
      width: 100%;
    }
  }

  .table {
    font-size: 0.8em;
    td {
      vertical-align: middle;
      padding-top: 2px;
      padding-bottom: 2px;
    }
    .btn-table {
      font-size: 12px;
      margin: 3px;
      padding: 3px 5px;
      width: 71px;
    }
  }
}

.modal-pedido-faturamento {
  .table {
    font-size: 0.8em;
    td,
    th {
      vertical-align: middle;
      padding-top: 2px;
      padding-bottom: 2px;
    }
    .btn-table {
      font-size: 12px;
      margin: 3px;
      padding: 3px 5px;
      width: 71px;
    }
  }

  .campo-nota {
    margin-bottom: 15px;
  }
}
