@import '../../theme/variables';

.page-title {
  width: 100%;
  text-align: left;
  font-size: 1.8em;
  font-weight: 600;
  color: $primary;
  text-transform: uppercase;
}
