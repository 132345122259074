@import '../../theme/variables';

.model-selecionar-equipe {
  .card-equipe {
    border: 3px solid $gray-300;
    margin: 10px 6px;
    padding: 10px;
    cursor: pointer;

    .nome-equipe {
      color: $primary;
      font-size: 1.2em;
    }

    .nome-usuario {
      color: $secondary;
      font-size: 1em;
    }
  }

  .equipe-selecionada {
    border: 3px solid $primary;
  }

  .observacao-equipe {
    color: $gray-600;
    font-size: 1em;
  }
}
