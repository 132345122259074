.table-resumo-mensal {
  margin-top: 20px;
  font-size: 0.8em;
  td {
    vertical-align: middle;
    padding: 2px;
  }
}

.home-web {
  display: block;
}

.home-mobile {
  display: none;
}

@media only screen and (max-width: 500px) {
  .home-web {
    display: none;
  }

  .home-mobile {
    display: block;
  }
}

.iframe-tableau,
.iframe-tableau iframe {
  width: 100%;
  height: 1500px;
}

.error-sem-meta {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: '800px';
  height: 'fit-content';
  margin: 'auto';
  text-align: 'center';
  background: '#c4c4c48c';
  padding: '40px';
  border-radius: '20px';
  color: '#000000';
}
