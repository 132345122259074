.modal-pedido-edita-produto {
  .linha-espaco {
    margin-top: 10px;
  }

  .tamanho {
    max-width: 500px;
  }

  .imagem-produto {
    text-align: center;
  }

  .imagem-produto img {
    max-width: 100%;
    max-height: 100px;
  }
}
