.table-comissao {
  font-size: 13px;

  .row {
    margin: 0;
  }

  .group-divider {
    border-top: 1px solid lightgray;
  }

  .group-divider-bold {
    border-top: 2px solid gray;
  }

  .p-2px {
    padding: 2px;
  }

  .input-group {
    .input-group-text {
      font-size: 13px;
      padding: 0 12px;
    }
  }
}
