@import '../../../theme/variables';

.form-group-header {
  color: $gray-600;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;

  hr {
    border-top: 1px solid $gray-600;
    opacity: 1;
  }
}
