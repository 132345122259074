@import '../../theme/variables';

.logout-btn {
  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    color: $primary;

    span {
      font-size: 1rem;
    }

    svg {
      max-height: fit-content;
      margin-bottom: 0.25rem;
    }
  }
}

.header-nav {
  position: sticky;
  font-weight: 300;
  top: 0;
  // left: 0%;
  // width: 100%;
  min-width: 350px;
  padding: 1rem 0;
  background-color: #fff;
  // background-color: transparent;
  z-index: 4;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  transition: 0.3s;

  .header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }

  .equipe-conectado {
    font-weight: 600;
  }

  .equipe-conectado span:hover {
    cursor: pointer;
    // text-decoration: underline;
  }

  .header-nome {
    display: flex;
    align-items: center;
    gap: 1rem;
    // margin-left: 230px;
    font-size: 1.5rem;
    color: $primary;

    p {
      font-weight: 300;
      padding: 0.5rem 0;
    }

    span {
      width: fit-content;
      display: flex;
      align-items: flex-end;
      gap: 1rem;
      height: fit-content;
      line-height: 1.5rem;
      border-radius: 1rem;
      transition: 0.3s;
      font-weight: 600;
      &:hover {
        background-color: #e6edf3;
      }
    }

    small {
      height: fit-content;
      background-color: #e6edf3;
      cursor: pointer;
      font-size: 1rem;
      color: #5b81d8;
      padding: 0.5rem 1rem;
      border-radius: 0.75rem;
    }
  }

  @media only screen and (max-width: 1025px) {
    .header-main {
      margin-left: 0px;

      .logout-btn {
        svg {
          font-size: inherit;
        }

        span {
          display: inherit;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .header-main {
      .logout-btn {
        svg {
          font-size: 1.75rem;
        }

        span {
          display: none;
        }
      }
    }

    .header-nome {
      padding-left: 16px;

      small {
        flex-shrink: 1;
        text-align: center;
      }
    }

    .header-nome {
      span {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  @media only screen and (max-width: 426px) {
    .header-nome {
      flex-direction: column;
      gap: 4px;
      align-items: start;

      span {
        font-size: 1rem;
      }
    }
  }

  .btn-navbar {
    background-color: #052674;
    border-radius: 0.75rem;
    z-index: 999;
  }

  .navbar-nav {
    // min-width: fit-content;
    align-self: center;
    display: flex;
    flex-wrap: nowrap;
    background-color: blue;

    .nav-item {
      .nav-user {
        display: flex;
        // background-color: blue;
        color: $primary;
        align-items: start;
        flex-direction: column;

        span,
        p {
          margin: 0;
        }

        span {
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1rem;
        }

        p {
          color: #5b81d8;
        }
      }

      button {
        text-decoration: none;
      }
    }
  }
}

.layout-content:not([style='margin-left: 250px;']) {
  .header-main {
    padding-left: 28px;
  }
}

.layout-content:not([style='margin-left: 0px;']) {
  .header-main {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .layout-content:not([style='margin-left: 250px;']) {
    .header-main {
      margin-left: 0px;
    }
  }

  .layout-content:not([style='margin-left: 250px;']) {
    .header-main {
      padding-left: 28px;
    }
  }

  .layout-content:not([style='margin-left: 0px;']) {
    .header-main {
      padding-left: 28px;
    }
  }
}
