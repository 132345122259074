@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham-Black_0.otf');
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham-BlackItalic_0.otf');
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham-Bold_0.otf');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham-BoldItalic_0.otf');
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham-Light_0.otf');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham-LightItalic_0.otf');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham-Medium_0.otf');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham-MediumItalic_0.otf');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham-Thin_0.otf');
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham-ThinItalic_0.otf');
  font-style: italic;
  font-weight: 100;
}
