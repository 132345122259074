@import './fonts';
@import './variables';
@import '~bootstrap/scss/bootstrap.scss';

body,
html {
  font-family: $font-family;
  background-color: $white;
  min-width: 360px;
  height: 100%;
  font-size: 13px;
}

.form-control {
  font-size: 1em;
  height: 30px;
  border-radius: none;
}

.rmsc {
  --rmsc-h: 29px !important;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $gray-400 !important;
  opacity: 1; /* Firefox */
  font-weight: 300 !important;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $gray-400 !important;
  font-weight: 300 !important;
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $gray-400 !important;
  font-weight: 300 !important;
}

#root {
  height: 100%;
}

.btn:active,
.btn:focus {
  box-shadow: none !important;
}

.btn-secondary {
  color: $white;
}

.btn-secondary:hover,
.btn:active,
.btn:focus {
  &:not(.btn-link) {
    color: $white !important;
  }
}

.table-cadastro {
  tr {
    border-bottom: 2px solid white;
  }

  td {
    background: $gray-200;
    vertical-align: middle;
  }

  th {
    background: $gray-600;
    color: $white;
    padding-top: 5px;
    padding-bottom: 5px;
    vertical-align: middle;
  }
}

.invalid-feedback-error {
  color: $red;
  font-family:
    system-ui,
    -apple-system,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    'Liberation Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
}

.action-btns {
  .btn {
    margin: 1px;
    margin-left: 5px;
    width: 32px;
  }
}

.filter-bar {
  margin-top: 30px;
  font-size: small;
}
.bg-gray-300 {
  background-color: $gray-300;
}
.bg-gray-400 {
  background-color: $gray-400;
}
.bg-gray-500 {
  background-color: $gray-500;
}
.bg-gray-600 {
  background-color: $gray-600;
}
.bg-gray-700 {
  background-color: $gray-700;
}
.bg-gray-800 {
  background-color: $gray-800;
}
.bg-gray-900 {
  background-color: $gray-900;
}

.select-form-control {
  font-size: 12px;

  div[class$='control'] {
    min-height: 20px;
  }

  div[class$='indicatorContainer'] {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.multi-select {
  font-size: 12px;
}

.text-secondary_darker {
  color: $secondary_darker;
}

.table-xs {
  font-size: 0.8em;

  td {
    vertical-align: middle;
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

.border-top {
  border-top: 1px solid lightgray;
}

.border-top-bold {
  border-top: 2px solid gray;
}

ul[role='listbox'] {
  max-height: 400px;
  overflow-y: auto;
}

.text-sm {
  font-size: 10px;
}

.text-gray-300 {
  color: $gray-300;
}
.text-gray-400 {
  color: $gray-400;
}
.text-gray-500 {
  color: $gray-500;
}
.text-gray-600 {
  color: $gray-600;
}
.text-gray-700 {
  color: $gray-700;
}
.text-gray-800 {
  color: $gray-800;
}
.text-gray-900 {
  color: $gray-900;
}
