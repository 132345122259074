.equipe-container {
  .texto {
    display: block;
    word-break: break-all;
  }

  .texto-light {
    font-size: 13px;
    font-weight: 300;
    word-break: break-all;
  }

  .equipe-usuario-container {
    border: 1px solid gray;
    padding: 10px;
    margin-bottom: 10px;
    background: #fafafa;

    .equipe-usuario-descricao-container {
      text-align: center;
    }

    @media (min-width: 992px) {
      .equipe-usuario-descricao-container {
        border-right: 1px solid gray;
      }
    }
  }

  @media (max-width: 1280px) {
    .px-5 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
  }

  //lg 992px
  // @media (min-width: 992px) {
  //   .equipe-usuario-descricao-container {
  //     border-bottom: none;
  //     border-right: 1px solid gray;
  //     text-align: center;
  //   }
  // }

  // .btn {
  //   margin-top:10px
  // }

  .equipe-inativa th {
    background: light-gray;
  }
}
