.table-vendas {
  outline: 2px solid #7a7a7a;
  outline-offset: -2px;
  border-collapse: collapse;
  margin-bottom: 20px;
  padding: 0.1rem 0.1rem!important;
  font-size: 0.8rem;

  td {
    padding: 0.3rem 0.3rem!important;
    vertical-align: middle;
    text-align: center;
  }
  th {
    vertical-align: middle;
    text-align: center;
    width: 7%;
  }

  .border-style {
    border: 2px solid black;
  }
}
