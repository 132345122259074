@import '../../../theme/variables';

.modal-cadastro {
  .modal-header {
    border-radius: 0;
    background-color: $secondary;
  }

  .modal-body {
    .form-label {
      color: $gray-600;
      font-size: 0.8em;
      margin-bottom: 0;
    }

    .form-control {
      border-radius: 0;
    }

    ::placeholder {
      color: $gray-500;
      font-weight: 300;
    }

    .select-cadastro__control {
      border-radius: 0;
      border: 1px solid #ced4da;
    }

    .select-cadastro__placeholder {
      color: $gray-500;
      font-weight: 300;
    }

    .is-invalid .select-cadastro__control {
      border-color: #dc3545;
    }
  }

  .modal-footer {
    padding: 0;
    border-radius: 0;

    button {
      margin: 0;
      border-radius: 0;
      padding: 20px;
      background-color: $secondary;
      border: none;
      width: 100%;
    }

    button:hover,
    button:focus {
      text-decoration: underline;
      box-shadow: none;
      background-color: $secondary_darker;
    }
  }
}
