@import '../../theme/variables';

.footer {
  background-color: #e6edf3;
  color: $gray-600;
  padding: 15px;
  bottom: 0;
  position: relative;
  width: 100%;
  border-top: 1px solid $gray-300;
  margin-top: 15px;
}
