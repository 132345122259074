@import '../../theme/variables';

.sidebar-menu {
  position: relative;
  z-index: 1000;

  @media only screen and (max-width: 720px) {
    .sidenav {
      width: 0px;
    }
  }

  @media only screen and (min-width: 720px) {
    .sidenav {
      width: 250px;
    }
  }

  @media only screen and (max-width: 767px) {
    .sidenav {
      width: 250px;
      top: 88px !important;
    }

    #sideMenu {
      width: 0px;
      font-size: 1px;
    }

    #layoutMain {
      margin-left: 0px;
    }
  }

  @media only screen and (max-width: 500px) {
    .item-menu-site {
      display: none;
    }
  }

  .header-menu {
    padding: 2rem 1rem 1rem;
  }

  .sidebar {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    padding: 1rem;
    z-index: 100;
  }

  .btn-navbar {
    position: fixed;
    top: 16px;
    left: 24px;
    background-color: rgba(5, 38, 116, 0.75);
    color: #ffffff;
    border-radius: 0.75rem;
    z-index: 1000;

    &:hover {
      color: #07d5fe !important;
      background-color: #052674;
    }
  }

  .navbar-toggler-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 1rem;

    svg {
      font-size: 1.5rem;
      margin: 0;
    }
  }

  .sidebar li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .svg-inline--fa {
    margin-right: 5px;
  }

  .sidenav {
    display: flex;
    gap: 2rem;
    border-radius: 0 2rem 0 0;
    top: 0px;
    left: 0px;
    bottom: 0px;
    padding: 8rem 0;
    // color: $primary;
    color: #ffffff;
    position: fixed;
    z-index: 0;
    // background-color: $light;
    background: linear-gradient(0deg, #031d8c, #1244b7);
    overflow-x: hidden;
    transition: 0.2s;
    // padding: 2rem 1rem;
    box-shadow:
      0 2px 4px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);

    .header-menu {
      text-align: center;
      font-weight: 300;

      .header-menu-nome {
        font-weight: normal;
        margin-top: 10px;
      }
    }

    .logo {
      width: 100%;
    }

    .accordion-button {
      // max-width: 100%;
      background-color: transparent;
      box-shadow: none;
      border: none;
      border-radius: 1rem;

      // color: $gray-700;
      color: #ffffff;

      svg {
        color: #07d5fe;
      }
    }

    .accordion-button:hover {
      // color: $primary;
      color: #07d5fe;

      background-color: #052674;
    }

    .accordion-button::after {
      filter: saturate(0) invert(100%) brightness(10);
    }

    .accordion-item {
      border: none;
      background-color: transparent;

      ul {
        background-color: rgba(5, 38, 116, 0.5);
        padding: 0;
        border-radius: 1rem;
      }
    }
  }

  a,
  button.btn-link {
    padding: 16px;
    text-decoration: none;
    font-size: 1em;
    display: block;
    transition: 0.1s;
    // color: $gray-700;
    color: #ffffff;
    border: none;
    border-radius: 1rem;

    svg {
      color: #07d5fe;
    }
  }

  a:hover,
  button.btn-link:hover {
    // color: $primary;
    color: #07d5fe;
    background-color: #052674;
  }
}
