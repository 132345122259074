@import '../../../theme/variables';

.format-option-representada {
  color: $gray-500;
  font-size: 0.8em;
  .fantasia {
    color: $secondary;
    font-size: 1.2em;
  }
  .cnpj {
    font-size: 0.8em;
  }
}
