@import '../../../theme/variables';

.format-option-loja {
  color: $gray-500;
  font-size: 0.8em;
  .fantasia {
    color: $secondary;
    font-size: 1.2em;
  }
  .fantasia-danger {
    color: $danger;
    font-size: 1.2em;
  }
  .cnpj {
    font-size: 0.9em;
  }
  .telefone {
    margin-right: 15px;
  }
}
