@import '../../theme/variables';

.input-image-produto-container {
  border: 1px solid #ccc;

  label {
    cursor: pointer;
    font-size: 60px;
    color: $gray-600;

    span {
      font-size: 14px;
      font-weight: 300;
    }
  }

  img {
    width: 33%;
  }
}
