@import '../../theme/variables';

.form-meus-dados {
  .form-label {
    color: $gray-600;
    font-size: 0.8em;
    margin-bottom: 0;
  }

  .form-control {
    border-radius: 0;
    margin-bottom: 10px !important;
  }

  ::placeholder {
    color: $gray-500;
    font-weight: 300;
  }

  .select-cadastro__control {
    border-radius: 0;
    border: 1px solid #ced4da;
  }

  .select-cadastro__placeholder {
    color: $gray-500;
    font-weight: 300;
  }

  .is-invalid .select-cadastro__control {
    border-color: #dc3545;
  }
}
