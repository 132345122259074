.filter-bar4 {
  margin-top: 0px;
  font-size: small;

  button {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 5px;
    margin-top: 19px;
  }
}
