@media only screen and (max-width: 720px) {
  .layout-content {
    margin-left: 0px !important;
  }
}

@media only screen and (min-width: 720px) {
  .layout-content {
    margin-left: 250px;
  }
}

.layout-content {
  // padding-top: 88px;

  main {
    padding: 15px 25px;
    margin-bottom: -54px;
  }
}

.layout-content:not([style='margin-left: 250px;']) {
  .header-main {
    padding-left: 28px;
  }
}

.layout-content:not([style='margin-left: 0px;']) {
  .header-main {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .layout-content:not([style='margin-left: 250px;']) {
    .header-main {
      margin-left: 0px;
    }
  }

  .layout-content:not([style='margin-left: 250px;']) {
    .header-main {
      padding-left: 28px;
    }
  }

  .layout-content:not([style='margin-left: 0px;']) {
    .header-main {
      padding-left: 28px;
    }
  }
}
